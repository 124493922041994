<template>
  <div id="app">
    <h1>Golingo AI Chat</h1>
    <div class="chat-container">
      <div
        v-for="(message, index) in messages"
        :key="index"
        :class="['message', message.sender]"
      >
        <p>{{ message.text }}</p>
      </div>
    </div>
    <form @submit.prevent="sendMessage">
      <input
        type="text"
        v-model="userInput"
        placeholder="Type your message..."
        autofocus
        ref="userInput"
      />
      <button type="submit">Send</button>
    </form>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'App',
  data() {
    return {
      userInput: '',
      messages: [],
    };
  },
  methods: {
    async sendMessage() {
      if (this.userInput.trim() === '') return;

      // Add user's message to the messages array
      this.messages.push({ text: this.userInput, sender: 'user' });

      // Prepare the conversation history (up to 20 messages)
      const conversationHistory = this.messages.slice(-39).map((msg) => ({
        role: msg.sender === 'user' ? 'user' : 'assistant',
        content: msg.text,
      }));

      // Send the message to the backend API
      try {
        const response = await axios.post(
          'https://ifea39qb13.execute-api.us-east-1.amazonaws.com/prod/aichat',
          {
            messages: JSON.stringify(conversationHistory),
          }
        );

        // Parse the response
        let replyText = '';
        console.log(response)
        if (response.data.body.reply) {
          // If the response is structured as expected
          replyText = response.data.body.reply;
        }
        else {
          replyText = 'Sorry, I did not understand that.';
        }
        console.log(replyText)
        // Add bot's response to the messages array
        this.messages.push({ text: replyText, sender: 'bot' });

        // Scroll to the latest message
        this.$nextTick(() => {
          const container = this.$el.querySelector('.chat-container');
          container.scrollTop = container.scrollHeight;
        });
      } catch (error) {
        console.error(error);
        this.messages.push({ text: 'Sorry, something went wrong.', sender: 'bot' });
      }

      // Clear the input field
      this.userInput = '';

      // Refocus on the input field
      this.$refs.userInput.focus();
    },
  },
};
</script>

<style>
/* General Styles */
#app {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Arial', sans-serif;
}

/* Heading */
h1 {
  text-align: center;
  color: #444;
}

/* Chat Container */
.chat-container {
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 10px;
  height: 500px;
  overflow-y: auto;
  margin-bottom: 10px;
  background-color: #f9f9f9;
}

/* Messages */
.message {
  display: flex;
  margin-bottom: 10px;
}

.message.user {
  justify-content: flex-end;
}

.message.bot {
  justify-content: flex-start;
}

.message p {
  max-width: 70%;
  padding: 10px;
  border-radius: 15px;
  margin: 0;
  word-wrap: break-word;
}

.message.user p {
  background-color: #0084ff;
  color: white;
  border-bottom-right-radius: 0;
}

.message.bot p {
  background-color: #e5e5ea;
  color: black;
  border-bottom-left-radius: 0;
}

/* Form */
form {
  display: flex;
  align-items: center;
}

input[type='text'] {
  flex: 1;
  padding: 15px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 30px;
  margin-right: 10px;
  outline: none;
}

input[type='text']:focus {
  border-color: #0084ff;
}

button {
  padding: 12px 20px;
  font-size: 16px;
  background-color: #0084ff;
  color: white;
  border: none;
  border-radius: 30px;
  cursor: pointer;
}

button:hover {
  background-color: #006bbf;
}

button:focus {
  outline: none;
}

/* Mobile Responsiveness */
@media (max-width: 600px) {
  #app {
    padding: 10px;
  }

  .chat-container {
    height: 400px;
  }

  input[type='text'] {
    font-size: 14px;
  }

  button {
    font-size: 14px;
    padding: 10px 15px;
  }
}
</style>